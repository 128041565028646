'use client'

import React, { ReactNode, useMemo, useState } from 'react'
import { DialogContentProps } from '@radix-ui/react-alert-dialog'
import {
  Dialog as BaseDialog,
  DialogContent,
  DialogTrigger,
  DialogTitle,
  DialogHeader,
  DialogFooter,
  DialogClose,
} from '../ui/dialog'
import { PoNVoid } from '@/types'
import Button, { ButtonProps } from '../button'
import LoadingButton from '../loading-button'
import { cls, preventDefault, whisper } from '@/utils'
import { createRoot } from 'react-dom/client'

export { DialogTrigger, DialogContent, DialogTitle, DialogHeader, DialogFooter, DialogClose }

export interface DialogProps extends Omit<DialogContentProps, 'title'> {
  className?: string
  titleClassName?: string
  closeClassName?: string
  title?: ReactNode
  description?: ReactNode
  footer?: ReactNode
  footerClassName?: string
  okText?: ReactNode
  maskClosable?: boolean
  cancelText?: ReactNode
  onOk?: (e?: any) => PoNVoid
  onCancel?: (e?: any) => PoNVoid
  okButtonProps?: Partial<ButtonProps>
  cancelButtonProps?: Partial<ButtonProps>
  trigger?: ReactNode
  children?: ReactNode
  childrenClassName?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export default function Dialog({
  className,
  title,
  titleClassName,
  closeClassName,
  description,
  footer,
  footerClassName,
  maskClosable,
  okText = 'OK',
  cancelText = 'Cancel',
  onOk,
  onCancel,
  okButtonProps,
  cancelButtonProps,
  children,
  childrenClassName,
  trigger,
  onOpenAutoFocus,
  open,
  onOpenChange,
}: DialogProps) {
  const [innerOpen, setInnerOpen] = useState(false)

  const handleOk = async (e: any) => {
    await onOk?.(e)
    setInnerOpen(false)
  }

  const maskClosableProps = useMemo(
    () =>
      maskClosable
        ? {}
        : {
            onPointerDownOutside: preventDefault,
            onInteractOutside: preventDefault,
            onEscapeKeyDown: preventDefault,
          },
    [maskClosable],
  )

  return (
    <BaseDialog
      open={open ?? innerOpen}
      onOpenChange={(open) => {
        setInnerOpen(open)
        onOpenChange?.(open)
      }}
    >
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        className={cls('w-100 rounded-lg max-w-[calc(100vw-32px)] md:w-[480px]', className)}
        closeClassName={closeClassName}
        onOpenAutoFocus={onOpenAutoFocus}
        onCancel={onCancel}
        {...maskClosableProps}
      >
        <DialogHeader className={cls('text-start', titleClassName)}>
          {title ? <DialogTitle className='font-semibold text-heading-lg tracking-45'>{title}</DialogTitle> : null}
          {description ? <DialogTitle>{description}</DialogTitle> : null}
        </DialogHeader>
        <div className={cls('text-body-md min-h-8', childrenClassName)}>{children}</div>
        {footer !== null ? (
          <DialogFooter className={footerClassName}>
            {footer ? (
              footer
            ) : (
              <div className='flex gap-4 justify-end w-full'>
                <DialogClose asChild>
                  <Button
                    variant='outline'
                    {...cancelButtonProps}
                    className={cls('rounded-lg border-border shadow-none', cancelButtonProps?.className)}
                    onClick={onCancel}
                  >
                    {cancelText}
                  </Button>
                </DialogClose>
                <LoadingButton
                  variant='primary'
                  {...okButtonProps}
                  className={cls('rounded-lg', okButtonProps?.className)}
                  onClick={handleOk}
                >
                  {okText}
                </LoadingButton>
              </div>
            )}
          </DialogFooter>
        ) : null}
      </DialogContent>
    </BaseDialog>
  )
}

let root: any = null
export const confirm = (config: DialogProps & { message: string }) => {
  if (root) return
  let mounted = true
  const open = true
  const div = document.createElement('div')
  document.body.appendChild(div)
  const defaultFooter = (
    <div className='flex gap-3 pt-8 w-full'>
      <LoadingButton
        variant='outline'
        className={cls('flex-1', config.cancelButtonProps?.className)}
        onClick={async () => {
          config.onCancel && (await config.onCancel())
          root && root.unmount()
          root = null
          mounted = false
        }}
      >
        {config.cancelText || 'Cancel'}
      </LoadingButton>
      <LoadingButton
        variant='primary'
        className={cls('flex-1', config.okButtonProps?.className)}
        onClick={async () => {
          config.onOk && (await config.onOk())
          root.unmount()
          root = null
          mounted = false
          config.onOk && config.onOk()
        }}
      >
        {config.okText || 'OK'}
      </LoadingButton>
    </div>
  )
  function renderDialog() {
    const myCom = (
      <Dialog
        open={open}
        title={config.title || ''}
        footer={config.footer || defaultFooter}
        className='w-[400px] md:w-[400px]'
        onCancel={() => {
          root.unmount()
          root = null
          mounted = false
          config.onCancel && config.onCancel()
        }}
      >
        <div className='text-text-subdued mt-1'>{config.message}</div>
      </Dialog>
    )
    if (!mounted) return
    // @ts-ignore
    root = createRoot(div)
    root.render(myCom)
  }

  renderDialog()
}
